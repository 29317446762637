'use client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import {
  AttachFilesRequestBody,
  TokenizeFileRequestBody,
  TokenizeFileResponseBody,
} from 'bff';
import { useCallback, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
  UploadFile,
  useApiError,
  useFileUpload,
  DOCUMENTATION_FILES_QUERY_KEY,
  UploadFileRef,
} from 'shared-components';
import {
  Button,
  cn,
  Combobox,
  ComboboxOption,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  useToast,
} from 'ui';
import z from 'zod';
import { MAX_CLAIM_FILES_PER_UPLOAD } from 'piramid-constants';
import { Tag } from 'database';

const uploadFileCustomFormData = z.object({
  no_tagged_files: z.string(),
});

export type UploadFileCustomFormData = z.infer<typeof uploadFileCustomFormData>;

export const UploadFileCustom = ({
  tokenizeFile,
  classNames,
  addFiles,
  queryKey,
  uploadMessage,
  searchTags,
  createTag,
  updateFileTag,
  withTagsSelector,
}: {
  classNames?: {
    container?: string;
    input?: string;
  };
  tokenizeFile: (
    input: TokenizeFileRequestBody,
  ) => Promise<TokenizeFileResponseBody>;
  addFiles: (files: AttachFilesRequestBody['files']) => Promise<any>;
  searchTags?: (input: { search: string }) => Promise<{ tags: Tag[] }>;
  createTag?: (tag: string) => Promise<{ tag: Tag } | undefined>;
  updateFileTag?: (fileId: string, tagId: string) => Promise<any>;
  queryKey: string;
  uploadMessage?: string;
  withTagsSelector?: boolean;
}) => {
  const [selectedTag, setSelectedTag] = useState<ComboboxOption | null>(null);

  const form = useForm<UploadFileCustomFormData>({
    resolver: zodResolver(uploadFileCustomFormData),
  });

  const file = useFileUpload({
    tokenizer: async (fileData) => tokenizeFile(fileData),
    maxFiles: MAX_CLAIM_FILES_PER_UPLOAD,
  });

  const [isLoading, setIsLoading] = useState(false);

  const { handleError } = useApiError();

  const { toast } = useToast();

  const queryClient = useQueryClient();

  const uploadFileRef = useRef<UploadFileRef>(null);

  const onUploadFileCustom = useCallback(() => {
    console.log('selectedTag', selectedTag);
    setIsLoading(true);
    addFiles(
      file.files.map((file) => ({
        token: file.token,
        tags: selectedTag ? [selectedTag.value.toString()] : [],
      })),
    )
      .then(() => {
        queryClient.invalidateQueries([DOCUMENTATION_FILES_QUERY_KEY]);
        setSelectedTag(null);
        uploadFileRef.current?.reset();

        toast({
          title: 'Archivos adjuntados',
          description: 'Los archivos se han adjuntado correctamente',
        });
      })
      .catch(handleError)
      .finally(() => {
        setIsLoading(false);
        file.setFiles([]);
        form.reset();
      });
  }, [file.files, addFiles, selectedTag, setSelectedTag]);

  const formId = 'upload-no-tagged-file-form';

  return (
    <div className={classNames?.container}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onUploadFileCustom)} id={formId}>
          <FormField
            control={form.control}
            name='no_tagged_files'
            defaultValue=''
            render={() => {
              return (
                <FormItem>
                  <FormControl>
                    <UploadFile
                      ref={uploadFileRef}
                      {...file}
                      defaultValue={[]}
                      uploadMessage={uploadMessage}
                      className={classNames?.input}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          {file.files.length > 0 && (
            <div className='flex items-center flex-col mt-4 space-y-2'>
              {withTagsSelector && searchTags && createTag && updateFileTag ? (
                <>
                  <Combobox
                    placeholder='Buscar y seleccionar etiqueta'
                    onSelect={({ value, label }) => {
                      console.log('value', value);
                      setSelectedTag({ value, label });
                    }}
                    loadOptions={async (search) =>
                      searchTags({ search }).then((res) =>
                        res.tags.map((t) => ({
                          label: t.name,
                          value: t.id.toString(),
                        })),
                      )
                    }
                    defaultOptions={[]}
                    emptyStateMessage='No se encontraron etiquetas'
                    className={cn(
                      'max-w-full placeholder-stone-400 text-base_brand text-stone-400',
                    )}
                    render={({ label }) => <>{label}</>}
                    onCreate={async (newTag): Promise<Tag> => {
                      const response = await createTag(newTag);
                      if (response?.tag) {
                        setSelectedTag({
                          value: response.tag.id.toString(),
                          label: response.tag.name,
                        });
                        return response.tag;
                      }
                      throw new Error('Failed to create tag');
                    }}
                  />
                  <div className='flex justify-end w-full'>
                    <Button
                      type='submit'
                      disabled={!selectedTag}
                      className='mt-0 h-8 rounded-md font-medium text-base_brand text-white py-2 px-5'
                    >
                      Etiquetar y cargar
                    </Button>
                  </div>
                </>
              ) : (
                <div className='flex justify-end w-full'>
                  <Button
                    disabled={isLoading}
                    loading={isLoading}
                    type='submit'
                    className='text-base_brand h-8 py-2 px-3 font-medium text-white bg-primary'
                  >
                    Cargar documento
                  </Button>
                </div>
              )}
            </div>
          )}
        </form>
      </Form>
    </div>
  );
};
