import React from 'react';

interface TextFilePreviewProps {
  previewURL: string;
}

export const TextFilePreview = ({ previewURL }: TextFilePreviewProps) => {
  const [content, setContent] = React.useState<string>('');

  React.useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await fetch(previewURL);
        const text = await response.text();
        setContent(text);
      } catch (error) {
        console.error('Error fetching file:', error);
      }
    };

    fetchContent();
  }, [previewURL]);

  return (
    <div className='w-full h-full rounded-md border bg-white p-4 overflow-auto'>
      <pre className='text-sm whitespace-pre-wrap'>{content}</pre>
    </div>
  );
};
