'use client';

import {
  AckFileRequestPlaceholderRequestBody,
  AckFileRequestPlaceholderRequestParams,
  AckFileRequestPlaceholderResponseBody,
  TokenizeFileRequestBody,
  TokenizeFileResponseBody,
} from 'bff';
import React, { useContext } from 'react';

export interface FileRequestContextProps {
  readOnly?: boolean;
  confirmFileRequestPlaceholder: (data: {
    params: AckFileRequestPlaceholderRequestParams;
    data: AckFileRequestPlaceholderRequestBody;
  }) => Promise<AckFileRequestPlaceholderResponseBody>;
  tokenizeFile: (input: {
    data: TokenizeFileRequestBody;
  }) => Promise<TokenizeFileResponseBody>;
  queryKey?: string;
}

const FileRequestContext = React.createContext<
  FileRequestContextProps | undefined
>(undefined);

export const useFileRequest = () => useContext(FileRequestContext)!;

export const FileRequestProvider = ({
  children,
  ...props
}: FileRequestContextProps & {
  children: React.ReactNode;
}) => {
  return (
    <FileRequestContext.Provider value={props}>
      {children}
    </FileRequestContext.Provider>
  );
};
