'use client';
import { useQuery } from '@tanstack/react-query';
import {
  AttachFilesRequestBody,
  ListFilesRequestQuery,
  ListFilesResponseBody,
  OrderRelationTypeMetadata,
  TokenizeFileRequestBody,
  TokenizeFileResponseBody,
} from 'bff';
import { FileTypes, Tag, UserType } from 'database';
import { format } from 'date-fns';
import { useTranslations } from 'i11n';
import debounce from 'lodash.debounce';
import React, { useCallback, useState, useMemo } from 'react';
import {
  FileCard,
  FileCardContent,
  FileCardIcon,
  FileCardList,
  FileCardPreview,
  FileRequestMessage,
  useFileRequest,
} from 'shared-components';
import {
  ComboboxOption,
  Input,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogTitle,
  Button,
  cn,
} from 'ui';
import { getHumanFileSize, filterDocumentsByVisibility } from 'utils';
import { DocumentTagsFilter, SearchTagsFn } from './DocumentTagsFilter';
import { IconDownload, IconEye, IconTag, IconX } from '@tabler/icons-react';
import { DialogClose } from '@radix-ui/react-dialog';
import {
  RetrievePendingFileRequestResponseBody,
  VisibilityCategories,
} from 'bff';
import { UploadFileCustom } from './UploadFileCustom';
import { ManualTaggingDialog } from './ManualTaggingDialog';
import { DocumentsEmptyState } from './DocumentsEmptyState';

export const DOCUMENTATION_FILES_QUERY_KEY = 'documentation-files-';

export const buildClaimPendingFileRequestQueryKey = (claimId: string) =>
  `claim-${claimId}-pending-file-request`;

export type ListFilesFN = (input: {
  query?: ListFilesRequestQuery;
  cookie?: string;
}) => Promise<ListFilesResponseBody>;

type File = ListFilesResponseBody['files'][number];

type ListPendingFileRequestFN = (
  params: OrderRelationTypeMetadata,
  cookie?: string,
) => Promise<RetrievePendingFileRequestResponseBody>;

export type DocumentsFileExplorerProps = {
  initialData: ListFilesResponseBody;
  searchTags: SearchTagsFn;
  listFiles: ListFilesFN;
  listPendingFileRequest: ListPendingFileRequestFN;
  onFilterChange?: (filters: {
    selectedValues: ComboboxOption[];
    selectedTypes: ComboboxOption[];
  }) => void;
  onSearch?: (search: string) => void;
  onOpenFile?: (file: File) => void;
  withSearch?: boolean;
  title?: string;
  docQueryKey?: string;
  withTags?: boolean;
  ownerId?: string;
  // @ts-ignore
  initialPendingFileRequests?: RetrievePendingFileRequestResponseBody;
  noTagPlaceholder?: boolean;
  tokenizeFile?: (
    input: TokenizeFileRequestBody,
  ) => Promise<TokenizeFileResponseBody>;
  addUntaggedFiles: (files: AttachFilesRequestBody['files']) => Promise<any>;
  visibility?: Partial<VisibilityCategories>;
  withManualTagging?: boolean;
  createTag: (tag: string) => Promise<any>;
  updateFileTag: (fileId: string, tagId: string) => Promise<any>;
  updateFilesTagsBatch: (
    files: { id: string; tag_id: string }[],
  ) => Promise<any>;
  withPlaceholderEmptyState?: boolean;
  userType?: UserType;
} & Pick<ListFilesRequestQuery, 'scope_id' | 'scope_type'>;

export const DocumentsFileExplorer = ({
  initialData,
  scope_id,
  scope_type,
  searchTags,
  listFiles,
  onFilterChange,
  onSearch,
  onOpenFile,
  withSearch = true,
  title,
  docQueryKey,
  ownerId,
  withTags = true,
  visibility = { tramitation: true, owner: true },
  initialPendingFileRequests,
  noTagPlaceholder = false,
  tokenizeFile,
  addUntaggedFiles,
  withManualTagging = false,
  createTag,
  updateFileTag,
  updateFilesTagsBatch,
  withPlaceholderEmptyState = true,
  userType,
  listPendingFileRequest,
}: DocumentsFileExplorerProps) => {
  const [selectedValues, setSelectedValues] = useState<ComboboxOption[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<ComboboxOption[]>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const { data } = useQuery({
    queryKey: [
      DOCUMENTATION_FILES_QUERY_KEY,
      docQueryKey,
      scope_id,
      selectedValues,
      selectedTypes,
      globalFilter,
    ] as const,
    queryFn: async (ctx) => {
      const [, , , selectedValues, selectedTypes, globalFilter] = ctx.queryKey;

      const response = await listFiles({
        query: {
          scope_id,
          scope_type,
          search: globalFilter,
          types: selectedTypes.map((v) => v.value as FileTypes),
          tags: selectedValues
            .map((v) => v.value)
            .filter((value): value is string => typeof value === 'string'),
          ownerId: ownerId,
        },
      });

      return {
        files: filterDocumentsByVisibility({
          files: response.files,
          ownerId,
          visibility,
          withTags,
        }),
      };
    },
    initialData,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const { data: pendingFileRequest } = useQuery({
    queryKey: [buildClaimPendingFileRequestQueryKey(scope_id)],
    queryFn: () =>
      listPendingFileRequest({
        relationType: 'claim',
        relationTypeId: scope_id,
      }),
    refetchOnMount: true,
    initialData: initialPendingFileRequests,
    enabled: !!initialPendingFileRequests,
  });

  const t = useTranslations();

  const debouncedGlobalFilter = useCallback(
    debounce((ev: React.ChangeEvent<HTMLInputElement>) => {
      setGlobalFilter(ev.target.value);

      if (onSearch) {
        onSearch(ev.target.value);
      }
    }, 500),
    [],
  );

  return (
    <div className='flex flex-col'>
      <div
        className={cn(
          'flex flex-row items-center justify-between space-x-2 w-full',
          {
            'mb-6': !initialPendingFileRequests,
          },
        )}
      >
        {title && (
          <div>
            <h1 className='text-stone-700 text-base font-medium'>{title}</h1>
          </div>
        )}

        {withSearch && (
          <div className='flex flex-row items-center space-x-2'>
            <Input
              onChange={debouncedGlobalFilter}
              placeholder='Buscar'
              className='h-8'
            />
            <DocumentTagsFilter
              searchTags={searchTags}
              setSelectedValues={(values) => {
                setSelectedValues(values);

                if (onFilterChange) {
                  onFilterChange({
                    selectedTypes,
                    selectedValues: values,
                  });
                }
              }}
              selectedValues={selectedValues}
            />
          </div>
        )}

        {withManualTagging &&
          data.files.length > 0 &&
          userType !== 'inhouse_lawyer' && (
            <ManualTaggingDialog
              files={data.files}
              // @ts-ignore
              searchTags={searchTags}
              createTag={createTag}
              updateFilesTagsBatch={updateFilesTagsBatch}
            />
          )}
      </div>

      {initialPendingFileRequests?.exists && userType !== 'inhouse_lawyer' && (
        <div className='flex flex-wrap md:flex-row gap-2 mb-6'>
          {(() => {
            const seenTags = new Map<string, boolean>();

            return pendingFileRequest!.file_requests!.flatMap(
              (fileRequest, requestIndex) =>
                fileRequest.placeholders
                  // Filter out placeholders with tags we've already seen
                  .filter((placeholder) => {
                    if (seenTags.has(placeholder.tag_id)) {
                      return false;
                    }
                    seenTags.set(placeholder.tag_id, true);
                    return true;
                  })
                  .map((placeholder: any, placeholderIndex: number) => {
                    return (
                      <div
                        className={cn(
                          'cursor-default flex items-center justify-center rounded-xl text-base_brand border border-primary py-1 px-2 h-6',
                        )}
                        key={`${requestIndex}-${placeholderIndex}`}
                      >
                        <span className='text-base_brand text-stone-700 font-medium'>
                          {placeholder.tag?.name}
                        </span>
                      </div>
                    );
                  }),
            );
          })()}
        </div>
      )}
      {initialPendingFileRequests?.exists && userType === 'inhouse_lawyer' && (
        <FileRequestMessage
          className='grid grid-cols-3 gap-6 space-y-0 mb-6'
          fileRequests={pendingFileRequest?.file_requests}
        />
      )}

      {data?.files?.length === 0 && (
        <DocumentsEmptyState
          initialPendingFileRequests={initialPendingFileRequests}
          tokenizeFile={tokenizeFile}
          addUntaggedFiles={addUntaggedFiles}
          searchTags={searchTags}
          createTag={createTag}
          updateFileTag={updateFileTag}
          userType={userType}
          withPlaceholderEmptyState={withPlaceholderEmptyState}
          visibility={visibility}
          withTags={withTags}
        />
      )}

      {data?.files?.length > 0 && (
        <FileCardList>
          {data.files.map((f) => {
            return (
              <Dialog>
                <DialogTrigger asChild>
                  <FileCard
                    className='relative h-[12.8rem] flex items-center justify-center group shadow-none rounded-sm'
                    onClick={() => onOpenFile && onOpenFile(f)}
                  >
                    <FileCardContent className='p-0 flex flex-col items-center shadow-none'>
                      <FileCardPreview
                        type={f.type}
                        previewURL={f.url}
                        className={cn('h-44 px-6', {
                          'w-80': f.type !== 'image',
                        })}
                      />
                      <div className='flex flex-row items-center justify-between w-full group-hover:visible invisible absolute bottom-0 left-0 transition-transform bg-gradient-to-t from-stone-50 via-stone-50 to-transparent h-12 px-4'>
                        <div className='flex flex-row items-center w-2/3'>
                          <FileCardIcon mimetype={f.mimetype!} />
                          <p className='text-base_brand font-medium truncate'>
                            {f.original_name}
                          </p>
                        </div>
                        {f.tags.length > 0 && (
                          <div className='flex flex-row items-center w-1/3 justify-end'>
                            <IconTag className='w-4 h-4 text-stone-300' />
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <p className='text-xs font-medium text-stone-300 truncate z-10'>
                                    {f.tags.map((tag) => tag.name).join(', ')}
                                  </p>
                                </TooltipTrigger>
                                <TooltipContent className='shadow-none !z-50'>
                                  <div className='flex flex-row items-center space-x-2'>
                                    <IconTag className='w-4 h-4 text-stone-300' />
                                    <p>
                                      {f.tags.map((tag) => tag.name).join(', ')}
                                    </p>
                                  </div>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                        )}
                      </div>
                    </FileCardContent>
                  </FileCard>
                </DialogTrigger>
                <DialogContent
                  hideClose
                  className='h-[45rem] max-w-[78rem] place-content-start rounded-[.75rem] shadow-none'
                >
                  <div className='flex items-center justify-between h-full'>
                    <DialogTitle className='font-semibold text-lg'>
                      Detalles del archivo
                    </DialogTitle>
                    <div className='flex items-center justify-center'>
                      <a
                        href={f.url}
                        target='_blank'
                        className='flex items-center justify-center text-primary underline-offset-4 hover:underline'
                      >
                        <IconDownload className='w-6 h-6 text-stone-400' />
                      </a>
                      <DialogClose asChild>
                        <Button variant='link'>
                          <IconX className='w-6 h-6 text-stone-400' />
                        </Button>
                      </DialogClose>
                    </div>
                  </div>
                  <div className='flex items-start'>
                    <div className='w-[54.375rem] h-[38.6875rem] py-5 px-2'>
                      <a target='_blank' href={f.url} download>
                        <FileCardPreview
                          previewURL={f.url}
                          type={f.type}
                          className='h-[90%]'
                          imageClassName='object-contain p-4'
                        />
                      </a>
                    </div>
                    <div className='h-full pt-12 ps-4'>
                      <Tabs defaultValue='detail' className='w-80 h-full'>
                        <TabsList className='justify-start bg-transparent'>
                          <TabsTrigger
                            value='detail'
                            triggerClassName='border-b-2 border-b-primary rounded-none px-0'
                          >
                            Información
                          </TabsTrigger>
                        </TabsList>

                        <TabsContent
                          className='flex flex-col space-y-4 mt-9'
                          value='detail'
                        >
                          <div className='flex flex-row items-center space-x-1'>
                            <FileCardIcon mimetype={f.mimetype!} />
                            <DialogTitle className='text-sm font-medium'>
                              {f.original_name}
                            </DialogTitle>
                          </div>
                          {f.tags.length > 0 && (
                            <div className='flex flex-row items-center justify-start space-x-2'>
                              <IconTag className='w-4 h-4 text-primary' />
                              <p className='text-sm font-medium text-stone-700'>
                                {f.tags.map((tag) => tag.name).join(', ')}
                              </p>
                            </div>
                          )}
                          <div className='flex flex-col'>
                            <ul className='flex flex-col space-y-6 text-base_brand'>
                              <li>
                                <span className='text-stone-400'>Tipo</span>
                                <br />
                                <span className='font-bold text-stone-700'>
                                  {t(`file_types.${f.type}`)}
                                </span>
                              </li>
                              <li>
                                <span className='text-stone-400'>Peso</span>
                                <br />
                                <span className='font-bold text-stone-700'>
                                  {getHumanFileSize(Number(f.size))}
                                </span>
                              </li>
                              {f.uploaded_by && (
                                <li>
                                  <span className='text-stone-400'>
                                    Subido por
                                  </span>
                                  <br />
                                  <span className='font-bold text-stone-700'>
                                    {f.uploaded_by.first_name}{' '}
                                    {f.uploaded_by.last_name}
                                  </span>
                                </li>
                              )}
                              {f.original_uploaded_at && (
                                <li>
                                  <span className='text-stone-400'>
                                    Fecha de subida
                                  </span>
                                  <br />
                                  <span className='font-bold text-stone-700'>
                                    {format(
                                      new Date(f.original_uploaded_at),
                                      'Pp',
                                    )}
                                  </span>
                                </li>
                              )}
                            </ul>
                          </div>
                        </TabsContent>
                      </Tabs>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            );
          })}
          {noTagPlaceholder && tokenizeFile && (
            <UploadFileCustom
              tokenizeFile={(input) => tokenizeFile(input)}
              classNames={{
                input: 'h-[12.8rem] border-slate-300',
              }}
              addFiles={addUntaggedFiles}
              queryKey={'untagged'}
              searchTags={searchTags}
              createTag={createTag}
              updateFileTag={updateFileTag}
              withTagsSelector={
                userType !== 'inhouse_lawyer' && userType !== 'third'
              }
            />
          )}
        </FileCardList>
      )}
    </div>
  );
};
