import * as React from 'react';
import { cn } from '../lib/utils';
import { VariantProps, cva } from 'class-variance-authority';

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const variants = cva(
  'flex h-20 w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      invalid: {
        true: 'border-red-500 border-2 outline-none',
        false: 'focus-visible:outline-none',
      },
      resize: {
        false: 'resize-none',
      },
    },
    defaultVariants: {
      invalid: false,
      resize: true,
    },
  },
);

/**
 * Displays a form textarea or a component that looks like a textarea.
 */
const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  TextareaProps & VariantProps<typeof variants>
>(({ className, invalid, resize, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        variants({
          className,
          invalid,
          resize,
        }),
      )}
      ref={ref}
      {...props}
    />
  );
});

Textarea.displayName = 'Textarea';

export { Textarea };
