import React from 'react';
import {
  FileCard,
  FileCardAuthor,
  FileCardContent,
  FileCardFilename,
  FileCardFooter,
  FileCardHeader,
  FileCardIcon,
  FileCardList,
  FileCardPreview,
  FileCardTag,
  FileCardTagList,
} from './FileCard';
import { ListAppraisalInvoicesResponseBody } from 'bff';

type File = ListAppraisalInvoicesResponseBody['invoices'][number];

export const FilePreviewList = ({ files }: { files: File[] }) => {
  return (
    <FileCardList>
      {files.map((f) => {
        return (
          <a key={f.id} target='_blank' href={f.url}>
            <FileCard>
              <FileCardHeader>
                <FileCardIcon mimetype={f.mimetype!} />
                <FileCardFilename>{f.original_name}</FileCardFilename>
              </FileCardHeader>
              <FileCardContent>
                <FileCardPreview
                  type={f.type}
                  previewURL={f.previewURL}
                  className='h-40'
                />
              </FileCardContent>
              <FileCardFooter>
                <FileCardTagList>
                  {f.tags.map((tag) => (
                    <FileCardTag>{tag.name}</FileCardTag>
                  ))}
                </FileCardTagList>
                <FileCardAuthor
                  createdAt={f.created_at}
                  uploadedByEmail={f.uploaded_by?.email!}
                />
              </FileCardFooter>
            </FileCard>
          </a>
        );
      })}
    </FileCardList>
  );
};
