'use client';
import { IconFilter } from '@tabler/icons-react';
import { useState } from 'react';
import {
  Badge,
  Button,
  ComboboxCheckbox,
  ComboboxOption,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'ui';

export const ComboboxFilter = <T extends ComboboxOption>({
  selectedValues,
  setSelectedValues,
  label,
  loadOptions,
  defaultOptions,
  defaultOpen = false,
}: {
  setSelectedValues: (selected: T[]) => void;
  selectedValues: T[];
  label?: string;
  loadOptions: (search: string) => Promise<T[]>;
  defaultOptions?: T[];
  defaultOpen?: boolean;
}) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>
            <PopoverTrigger asChild>
              <Button
                variant='outline'
                size='sm'
                className='h-8 border border-stone-300 text-stone-700 text-base_brand'
              >
                <IconFilter className='mr-2 h-4 w-4 flex-shrink-0' />
                <span className='capitalize'>{label}</span>
                {selectedValues.length > 0 && (
                  <>
                    <Separator orientation='vertical' className='mx-2 h-4' />
                    <Badge
                      variant='secondary'
                      className='rounded-sm px-1 font-normal lg:hidden'
                    >
                      {selectedValues.length}
                    </Badge>
                    <div className='hidden space-x-1 lg:flex'>
                      {
                        <Badge
                          variant='secondary'
                          className='rounded-sm px-1 font-normal'
                        >
                          {selectedValues.length} seleccionados
                        </Badge>
                      }
                    </div>
                  </>
                )}
              </Button>
            </PopoverTrigger>
          </TooltipTrigger>
          <TooltipContent>
            <p>
              {selectedValues.length > 0
                ? selectedValues
                    .map((selectedValue) => selectedValue.value)
                    .join(', ')
                : 'Ningun valor seleccionado'}
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <PopoverContent className='w-[200px] p-0' align='start'>
        <ComboboxCheckbox
          value={selectedValues}
          onClose={() => setOpen(false)}
          render={({ label }) => <>{label}</>}
          placeholder={`Buscar ${label}`}
          onSelect={({ value, label }) => {
            if (selectedValues.some((ele) => ele.value === value)) {
              setSelectedValues(
                selectedValues.filter((ele) => ele.value !== value),
              );
            } else {
              setSelectedValues([...selectedValues, { label, value }]);
            }
          }}
          loadOptions={loadOptions}
          defaultOptions={defaultOptions || selectedValues}
          emptyStateMessage=''
        />
      </PopoverContent>
    </Popover>
  );
};
