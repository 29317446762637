'use client';

import {
  AckFileRequestPlaceholderRequestBody,
  AckFileRequestPlaceholderRequestParams,
  AckFileRequestPlaceholderResponseBody,
  ListMessagesResponseBody,
  TokenizeFileRequestBody,
  TokenizeFileResponseBody,
} from 'bff';
import React, { useContext } from 'react';

export interface ChatContextProps {
  readOnly?: boolean;
  addMessage: (message: string) => Promise<any>;
  listMessages: () => Promise<ListMessagesResponseBody>;
  confirmFileRequestPlaceholder?: (data: {
    params: AckFileRequestPlaceholderRequestParams;
    data: AckFileRequestPlaceholderRequestBody;
  }) => Promise<AckFileRequestPlaceholderResponseBody>;
  tokenizeFile?: (input: {
    data: TokenizeFileRequestBody;
  }) => Promise<TokenizeFileResponseBody>;
}

const ChatContext = React.createContext<ChatContextProps | undefined>(
  undefined,
);

export const useChat = () => useContext(ChatContext)!;

export const ChatProvider = ({
  children,
  ...props
}: ChatContextProps & {
  children: React.ReactNode;
}) => {
  return <ChatContext.Provider value={props}>{children}</ChatContext.Provider>;
};
