import { DocumentEmptyStateIcon } from 'shared-components';
import { UploadFileCustom } from './UploadFileCustom';
import { UserType } from 'database';
import { TokenizeFileRequestBody, TokenizeFileResponseBody } from 'bff';
import { SearchTagsFn } from './DocumentTagsFilter';
import { VisibilityCategories } from 'bff';

type DocumentsEmptyStateProps = {
  initialPendingFileRequests: any;
  tokenizeFile?: (
    input: TokenizeFileRequestBody,
  ) => Promise<TokenizeFileResponseBody>;
  addUntaggedFiles: (files: any[]) => Promise<any>;
  searchTags: SearchTagsFn;
  createTag: (tag: string) => Promise<any>;
  updateFileTag: (fileId: string, tagId: string) => Promise<any>;
  userType?: UserType;
  withPlaceholderEmptyState: boolean;
  visibility: Partial<VisibilityCategories>;
  withTags: boolean;
};

const getEmptyStateMessage = (
  userType?: UserType,
  withTags?: boolean,
): string => {
  if (!userType || userType === 'inhouse_lawyer' || userType === 'third') {
    return '';
  }

  if ((userType === 'insurer' || userType === 'field_adjuster') && !withTags) {
    return '';
  }

  return 'Solicita archivos al reclamante';
};

export const DocumentsEmptyState = ({
  initialPendingFileRequests,
  tokenizeFile,
  addUntaggedFiles,
  searchTags,
  createTag,
  updateFileTag,
  userType,
  withPlaceholderEmptyState,
  visibility,
  withTags,
}: DocumentsEmptyStateProps) => {
  if (initialPendingFileRequests?.file_requests.length) {
    console.log(
      'initialPendingFileRequests',
      initialPendingFileRequests.file_requests.length,
    );
    return null;
  }

  const uploadFileProps = {
    tokenizeFile: (file: any) => tokenizeFile?.(file),
    classNames: {
      input: 'h-[12.8rem] border-slate-300',
    },
    addFiles: addUntaggedFiles,
    queryKey: 'untagged',
    uploadMessage: 'No se encontraron archivos',
    searchTags,
    createTag,
    updateFileTag,
  };

  if (userType === 'inhouse_lawyer' && !withTags) {
    return <UploadFileCustom {...uploadFileProps} withTagsSelector={false} />;
  }

  if (
    withPlaceholderEmptyState &&
    tokenizeFile &&
    userType !== 'inhouse_lawyer'
  ) {
    return (
      <div>
        <UploadFileCustom
          {...uploadFileProps}
          withTagsSelector={
            userType !== 'inhouse_lawyer' && userType !== 'third'
          }
        />
      </div>
    );
  }

  const emptyStateMessage = getEmptyStateMessage(userType, withTags);

  return (
    <div className='flex flex-col items-center p-10 border border-dashed border-stone-300 rounded-xl'>
      <DocumentEmptyStateIcon />
      <p className='text-stone-700 font-semibold text-xs text-center'>
        No se encontraron archivos <br />
        <span className='text-stone-400 font-medium text-2xs mt-2'>
          {emptyStateMessage}
        </span>
      </p>
    </div>
  );
};
