import { IconFileText } from '@tabler/icons-react';
import { FileTypes } from 'database';
import { flatten } from 'lodash';
import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { cn } from 'ui';
import { getHumanFileSize } from 'utils';
import { DocumentEmptyStateIcon } from './DocumentEmptyStateIcon';

const TWENTY_MB_IN_BYTES = 20 * 1024 * 1024;

export const mimetypes: {
  [K in FileTypes]: string[];
} = {
  other: ['*'],
  pdf: ['application/pdf'],
  audio: ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/webm'],
  video: ['video/mpeg', 'video/ogg', 'video/webm', 'video/mp4'],
  image: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/svg+xml'],
  document: [
    'text/plain',
    'text/csv',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ],
};

export interface FileInputProps {
  invalid?: boolean;
  maxFiles?: number;
  disabled?: boolean;
  fileTypes?: FileTypes[];
  maxFileSize?: number;
  onDropFiles: (files: File[]) => void;
}

const fileTypesLabels: {
  [K in FileTypes]: {
    plural: string;
    singular: string;
  };
} = {
  pdf: {
    plural: 'PDFs',
    singular: 'un PDF',
  },
  document: {
    plural: 'documentos',
    singular: 'un documento',
  },
  image: {
    plural: 'imágenes',
    singular: 'una imagen',
  },
  audio: {
    plural: 'audios',
    singular: 'un audio',
  },
  video: {
    plural: 'videos',
    singular: 'un video',
  },
  other: {
    plural: 'archivos',
    singular: 'cualquier archivo',
  },
};

export const FileInput = ({
  fileTypes = ['image', 'document'],
  maxFileSize = TWENTY_MB_IN_BYTES,
  disabled,
  onDropFiles,
  maxFiles = 5,
  invalid,
  label,
  docKey,
  children,
  isFileLoaded,
  className,
  uploadMessage,
  fromCreateClaim,
}: FileInputProps & {
  label?: string;
  docKey?: string;
  children?: React.ReactNode;
  isFileLoaded?: boolean;
  className?: string;
  uploadMessage?: string;
  fromCreateClaim?: boolean;
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [preview, setPreview] = useState<string | null>(null);

  const labelId = label?.replaceAll(' ', '-').toLowerCase() || '';

  const inputRef = useRef<HTMLInputElement>(null);

  const dataTransferItemsToFiles = useCallback(
    (dataTransferList: DataTransferItemList) => {
      const files: File[] = [];

      for (let i = 0; i < dataTransferList.length; i++) {
        const item = dataTransferList[i];

        const file = item.getAsFile();

        if (file) {
          files.push(file);
        }
      }

      return files;
    },
    [],
  );

  const supportedFileTypes = useMemo(
    () => flatten(fileTypes.map((type) => mimetypes[type])),
    [],
  );

  useEffect(() => {
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [preview]);

  const handleFile = useCallback(
    (files: File[]) => {
      console.log('files from handleFile args', files);

      if (!files.length) {
        setPreview(null);
        return;
      }

      const filteredFiles = files
        .filter((f) => {
          const sizeCheck = f.size <= maxFileSize;
          const filetypeCheck = fileTypes.includes('other' satisfies FileTypes)
            ? true
            : supportedFileTypes.includes(f.type);

          return sizeCheck && filetypeCheck;
        })
        .slice(0, maxFiles);

      if (filteredFiles[0]?.type.startsWith('image/')) {
        const previewUrl = URL.createObjectURL(filteredFiles[0]);
        setPreview(previewUrl);
      } else {
        setPreview(null);
      }

      onDropFiles(filteredFiles);
    },
    [onDropFiles, maxFileSize, fileTypes, supportedFileTypes, maxFiles],
  );

  return (
    <div
      onDrop={(ev) => {
        ev.preventDefault();

        if (disabled) return;

        if (ev.dataTransfer.items) {
          const files = dataTransferItemsToFiles(ev.dataTransfer.items);

          handleFile(files);
        }
        setIsDragging(false);
      }}
      onDragLeave={() => setIsDragging(false)}
      onDragEnter={() => {
        if (!disabled) {
          setIsDragging(true);
        }
      }}
      onDragOver={(ev) => ev.preventDefault()}
      className={cn(
        'hover:border-slate-700 hover:[&>*]:text-slate-700 relative transition-all rounded-sm border border-dashed border-piramid-black p-6',
        {
          'border-slate-700 [&>*]:text-slate-700': isDragging,
          'border-red-500': invalid,
          'border-solid border-primary': isFileLoaded,
        },
        className,
      )}
    >
      {!isDragging && (
        <input
          ref={inputRef}
          multiple={maxFiles > 1}
          onChange={(ev) => {
            if (ev.target.files?.length) {
              console.log('files to upload', ev.target.files);

              handleFile(Array.from(ev.target.files));
            }

            if (inputRef.current) {
              inputRef.current.value = '';
            }
          }}
          className={cn('opacity-0 absolute inset-0 w-full h-full', {
            'pointer-events-none touch-none': isDragging,
          })}
          disabled={disabled}
          type='file'
          id={`upload-file-${labelId}`}
        />
      )}

      <div
        className={cn('flex text-neutral-300 items-center flex-col', {
          'pointer-events-none cursor-not-allowed': !disabled,
        })}
      >
        {isFileLoaded ? (
          preview ? (
            <img
              src={preview}
              alt='Preview'
              className='w-20 h-20 object-cover rounded-sm'
            />
          ) : (
            <IconFileText className='w-20 h-20' />
          )
        ) : fromCreateClaim ? (
          <IconFileText className='w-20 h-20' />
        ) : (
          <DocumentEmptyStateIcon />
        )}

        {isFileLoaded ? (
          children
        ) : (
          <>
            {label ? (
              <p
                className={cn(
                  'font-medium text-[1rem] truncate text-center text-piramid-black max-w-full',
                )}
              >
                {label}
              </p>
            ) : (
              <p className='text-xs text-center text-stone-700 font-semibold'>
                {uploadMessage || ''}
              </p>
            )}

            <p className='text-stone-700 font-medium text-2xs mt-2'>
              Suelta aquí tu archivo o presiona para subirlo
            </p>

            <p className='text-stone-400 font-medium text-2xs mt-1'>
              {`Límite ${maxFiles} archivos - ${getHumanFileSize(maxFileSize)}`}
            </p>
          </>
        )}
      </div>
    </div>
  );
};
