import React from 'react';

export const DocumentEmptyStateIcon: React.FC<{
  style?: React.CSSProperties;
  color?: string;
}> = ({ style, color = '#44403c' }) => {
  return (
    <svg
      style={style}
      xmlns='http://www.w3.org/2000/svg'
      width={132}
      height={98}
      fill='none'
    >
      <g
        stroke='#A8A29E'
        strokeLinecap='round'
        strokeLinejoin='round'
        clipPath='url(#a)'
      >
        <path
          fill='#fff'
          d='m75.212 65.827-27.4 13.76a6.207 6.207 0 0 1-4.661.379 5.919 5.919 0 0 1-3.506-2.979L20.831 39.524a5.918 5.918 0 0 1-.295-4.59 6.207 6.207 0 0 1 3.087-3.514l19.18-9.632 20.42 6.5 14.782 29.435a5.92 5.92 0 0 1 .295 4.59 6.207 6.207 0 0 1-3.088 3.514Z'
        />
        <path d='m42.803 21.788 5.376 10.704a2.96 2.96 0 0 0 1.752 1.489 3.104 3.104 0 0 0 2.331-.19l10.96-5.503' />
      </g>
      <g
        stroke='#A8A29E'
        strokeLinecap='round'
        strokeLinejoin='round'
        clipPath='url(#b)'
      >
        <path
          fill='#fff'
          d='m88.657 79.028-29.104-9.644a6.207 6.207 0 0 1-3.564-3.03 5.919 5.919 0 0 1-.373-4.584l13.187-39.794a5.919 5.919 0 0 1 3.037-3.455 6.207 6.207 0 0 1 4.668-.301l20.373 6.75 9.842 19.035-10.361 31.267a5.919 5.919 0 0 1-3.037 3.455 6.208 6.208 0 0 1-4.668.301Z'
        />
        <path d='m96.88 24.97-3.768 11.37a2.96 2.96 0 0 0 .187 2.292 3.104 3.104 0 0 0 1.782 1.515l11.641 3.858' />
      </g>
      <g
        stroke='#A8A29E'
        strokeLinecap='round'
        strokeLinejoin='round'
        clipPath='url(#c)'
      >
        <path
          fill='#fff'
          d='M82.833 81.642H45.747a7.417 7.417 0 0 1-7.418-7.417v-51.92a7.417 7.417 0 0 1 7.418-7.418h25.96L90.25 33.43v40.795a7.417 7.417 0 0 1-7.417 7.417Z'
        />
        <path d='M71.707 14.887V29.72a3.709 3.709 0 0 0 3.709 3.709H90.25' />
      </g>
      <g clipPath='url(#d)'>
        <circle cx={64.918} cy={80.232} r={11.283} fill='#fff' />
        <path
          fill={color}
          d='M71.186 69.376A12.537 12.537 0 1 1 52.38 80.231l.007-.406a12.536 12.536 0 0 1 18.798-10.45Zm-6.269 14.617a1.254 1.254 0 0 0-1.253 1.253v.013a1.254 1.254 0 0 0 2.507 0v-.013a1.254 1.254 0 0 0-1.254-1.253Zm0-8.776a1.254 1.254 0 0 0-1.253 1.254v5.014a1.254 1.254 0 0 0 2.507 0v-5.014a1.254 1.254 0 0 0-1.254-1.254Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path
            fill='#fff'
            d='M.412 33.024 66.171 0l32.252 64.223-65.758 33.024z'
          />
        </clipPath>
        <clipPath id='b'>
          <path
            fill='#fff'
            d='M33.576 33.024 99.335 0l32.252 64.223L65.83 97.247z'
          />
        </clipPath>
        <clipPath id='c'>
          <path fill='#fff' d='M19.786 3.76h89.007v89.008H19.786z' />
        </clipPath>
        <clipPath id='d'>
          <path fill='#fff' d='M49.874 65.188h30.087v30.087H49.874z' />
        </clipPath>
      </defs>
    </svg>
  );
};
