import { IconTag } from '@tabler/icons-react';
import { SearchTagsRequestQuery, SearchTagsResponseBody } from 'bff';
import { Trash } from 'lucide-react';
import { nanoid } from 'nanoid';
import { useCallback, useState, forwardRef, useImperativeHandle } from 'react';
import {
  Button,
  ComboboxCheckbox,
  ComboboxOption,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  cn,
} from 'ui';
import { FileList, FileListItem, FileListLabel } from './File';
import { FileInput, FileInputProps } from './FileInput';

const TWENTY_MB_IN_BYTES = 20 * 1024 * 1024;

export interface FileUploadResponse {
  token: string;
  url: string;
}

export interface FileMeta {
  name: string;
  url: string;
}

export interface FileExtended {
  id: string;
  tags: ComboboxOption[];
  progress?: number;
  isLoading?: boolean;
  name: string;
  size: number;
}

export interface UploadFileProps
  extends Pick<FileInputProps, 'fileTypes' | 'maxFileSize'> {
  searchTags?: (
    query: SearchTagsRequestQuery,
    cookie?: string,
  ) => Promise<SearchTagsResponseBody>;
  includeTagger?: boolean;
  children?: React.ReactNode;
  defaultValue?: FileExtended[];
  invalid?: boolean;
  maxFiles?: number;
  onRemoveFile: (index: number) => void;
  onUploadFile: (
    file: File,
    notifyProgress: (progress: number) => void,
  ) => Promise<FileUploadResponse>;
  onUpdateFile?: (index: number, file: Partial<FileExtended>) => void;
  maxTagsPerFile?: number;
  disabled?: boolean;
  label?: string;
  docKey?: string;
  uploadMessage?: string;
  className?: string;
  fromCreateClaim?: boolean;
}

export interface UploadFileRef {
  reset: () => void;
}

export const UploadFile = forwardRef<UploadFileRef, UploadFileProps>(
  (
    {
      searchTags,
      includeTagger = false,
      fileTypes,
      maxFileSize = TWENTY_MB_IN_BYTES,
      onUploadFile,
      maxTagsPerFile = 2,
      onRemoveFile,
      maxFiles = 1,
      invalid,
      defaultValue = [],
      children,
      onUpdateFile,
      disabled,
      label,
      docKey,
      uploadMessage,
      className,
      fromCreateClaim,
    },
    ref,
  ) => {
    const [files, setFiles] = useState<FileExtended[]>(defaultValue);

    useImperativeHandle(ref, () => ({
      reset: () => setFiles([]),
    }));

    const updateFile = useCallback(
      (
        targetId: string,
        modifier: (file: FileExtended) => Partial<FileExtended>,
      ) => {
        setFiles((files) =>
          files.map((file) => {
            if (file.id === targetId) {
              return {
                ...file,
                ...modifier(file),
              };
            }

            return file;
          }),
        );
      },
      [],
    );

    const handleUploadFile = useCallback(
      (file: File) => {
        const id = nanoid();

        console.log('uploading file', file);

        setFiles((files) =>
          files.concat([
            {
              name: file.name,
              size: file.size,
              progress: 0,
              isLoading: true,
              tags: [],
              id,
            },
          ]),
        );

        onUploadFile(file, (progress) => {
          updateFile(id, () => ({
            progress,
          }));
        }).finally(() => {
          updateFile(id, () => ({
            isLoading: false,
          }));
        });
      },
      [setFiles, files],
    );

    return (
      <div className=''>
        <FileInput
          invalid={invalid}
          maxFiles={maxFiles}
          disabled={files.length === maxFiles}
          onDropFiles={(files) => {
            for (const file of files) {
              handleUploadFile(file);
            }
          }}
          fileTypes={fileTypes}
          maxFileSize={maxFileSize}
          label={label}
          docKey={docKey}
          isFileLoaded={files.length > 0}
          className={className}
          uploadMessage={uploadMessage}
          fromCreateClaim={fromCreateClaim}
        >
          {files.length > 0 && (
            <FileList className='h-10 overflow-y-scroll pointer-events-auto z-10 cursor-auto'>
              {files.map(({ name, isLoading, tags }, index) => {
                return (
                  <FileListItem
                    key={index}
                    className='border-0 h-6 text-[#B7B7C0]'
                  >
                    <FileListLabel
                      withDefaultIcon
                      withMimetypeIcon={false}
                      className='justify-around'
                    >
                      {name}
                    </FileListLabel>
                    {isLoading ? (
                      <Spinner size={'md'} />
                    ) : (
                      <div>
                        <Button
                          onClick={() => {
                            setFiles((files) =>
                              files.filter((_, i) => i !== index),
                            );
                            onRemoveFile(index);
                          }}
                          className={cn(
                            'rounded-l-none rounded-tr-none p-0 px-1 h-auto',
                            {
                              'px-3': includeTagger,
                            },
                            {
                              'pointer-events-auto': !disabled,
                            },
                          )}
                          variant={'ghost'}
                          type='button'
                        >
                          <Trash className='w-3 h-3' />
                        </Button>
                        {includeTagger && !!searchTags && (
                          <Popover>
                            <PopoverTrigger asChild>
                              <Button
                                className={cn(
                                  'rounded-l-none rounded-tr-none p-0 px-1',
                                  {
                                    'px-3': includeTagger,
                                  },
                                )}
                                variant={'ghost'}
                                type='button'
                              >
                                <IconTag className='w-2 h-2' />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent
                              className='w-[200px] p-0'
                              align='start'
                            >
                              <ComboboxCheckbox
                                value={tags}
                                onClose={() => {}}
                                render={({ label }) => <>{label}</>}
                                placeholder='Buscar etiquetas'
                                onSelect={({ value, label }) => {
                                  const shouldRemove = tags.some(
                                    (ele) => ele.value === value,
                                  );

                                  const tagsUpdated = shouldRemove
                                    ? tags.filter(
                                        (tag) =>
                                          tag.value.toString() !==
                                          value.toString(),
                                      )
                                    : maxTagsPerFile <= tags.length
                                    ? tags
                                    : [...tags, { label, value }];

                                  onUpdateFile(index, { tags: tagsUpdated });

                                  setFiles((prevFiles) => {
                                    return prevFiles.map((file, iterIndex) => {
                                      if (iterIndex !== index) return file;

                                      return {
                                        ...file,
                                        tags: tagsUpdated,
                                      };
                                    });
                                  });
                                }}
                                loadOptions={async (search) =>
                                  searchTags({ search }).then((res) =>
                                    res.tags.map((t) => {
                                      return {
                                        label: t.name,
                                        value: t.id.toString(),
                                      };
                                    }),
                                  )
                                }
                                defaultOptions={tags}
                                emptyStateMessage=''
                              />
                            </PopoverContent>
                          </Popover>
                        )}
                      </div>
                    )}
                  </FileListItem>
                );
              })}
            </FileList>
          )}
        </FileInput>

        {children}
      </div>
    );
  },
);
