'use client';
import { useQueryClient } from '@tanstack/react-query';
import {
  FileRequestPlaceholderWithRelations,
  FileRequestWithRelations,
} from 'bff';
import { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import {
  buildClaimPendingFileRequestQueryKey,
  DOCUMENTATION_FILES_QUERY_KEY,
  FileListPlaceholder,
  UploadFile,
  UploadFileRef,
  useApiError,
  useFileRequest,
} from 'shared-components';
import { Button, Label, useToast } from 'ui';
import wretch from 'wretch';
import FormDataAddon from 'wretch/addons/formData';
import { FileTypes } from 'database';
import { useSharedClaim } from '../contexts/SharedClaimContext';
import { withEnablement } from '../hocs/withEnablement';

export const buildClaimDocumentationDataListQueryKey = (claimId: string) =>
  `claim-${claimId}-files`;

const UnsafeFileRequestPlaceholder = ({
  placeholder,
}: {
  placeholder: FileRequestPlaceholderWithRelations;
}) => {
  const [files, setFiles] = useState<
    {
      token: string;
      name: string;
      size: number;
    }[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isFileLoaded, setIsFileLoaded] = useState(false);

  const { confirmFileRequestPlaceholder, tokenizeFile, readOnly, queryKey } =
    useFileRequest();

  const { claim } = useSharedClaim();

  const { handleError } = useApiError();

  const { toast } = useToast();

  const queryClient = useQueryClient();

  const uploadFileRef = useRef<UploadFileRef>(null);

  const handleConfirmPlaceholder = useCallback(() => {
    setIsLoading(true);

    confirmFileRequestPlaceholder({
      params: {
        relationTypeId: claim.id,
        fileRequestId: placeholder.file_request_id,
        placeholderId: placeholder.id,
      },
      data: {
        files,
      },
    })
      .then(() => {
        queryClient.invalidateQueries([queryKey]);
        queryClient.invalidateQueries([
          buildClaimPendingFileRequestQueryKey(claim.id),
        ]);
        queryClient.invalidateQueries([
          buildClaimDocumentationDataListQueryKey(claim.id),
        ]);
        queryClient.invalidateQueries([DOCUMENTATION_FILES_QUERY_KEY]);
      })
      .then(() => {
        uploadFileRef.current?.reset();

        toast({
          title: 'Documentación enviada',
          description: 'La documentación ha sido enviada correctamente',
        });

        setFiles([]);
      })
      .finally(() => setIsLoading(false))
      .catch(handleError);
  }, [files, setFiles]);

  return (
    <div className='flex flex-col space-y-2 h-full w-full' key={placeholder.id}>
      <UploadFile
        disabled={readOnly}
        ref={uploadFileRef}
        fileTypes={placeholder.types as FileTypes[]}
        key={placeholder.id}
        onRemoveFile={(index) =>
          setFiles((files) => files.filter((_, i) => i !== index))
        }
        maxFiles={placeholder.max_files}
        onUploadFile={async (file) => {
          const { fields, presigned_url, token, fetch_url } =
            await tokenizeFile({
              data: {
                name: file.name,
                size: file.size,
              },
            });

          await wretch(presigned_url)
            .addon(FormDataAddon)
            .formData({
              ...fields,
              file,
            })
            .post();

          setFiles((files) =>
            files.concat([
              {
                name: file.name,
                size: file.size,
                token,
              },
            ]),
          );
          console.log('lg files', files);
          setIsFileLoaded(true);

          return {
            url: fetch_url,
            token,
          };
        }}
        label={placeholder.tag.name}
        className='max-h-[12.8rem] border-slate-300 rounded-xl'
      >
        {placeholder.context && (
          <p className='text-sm text-muted-foreground'>{placeholder.context}</p>
        )}
      </UploadFile>

      {files.length > 0 && (
        <div className='flex items-center justify-end col-span-3'>
          <Button
            disabled={isLoading || !isFileLoaded}
            onClick={handleConfirmPlaceholder}
            loading={isLoading}
            type='button'
            className='text-base_brand h-8 py-2 px-3 font-medium text-white bg-primary'
          >
            Enviar documento
          </Button>
        </div>
      )}
    </div>
  );
};

const FileRequestPlaceholder = withEnablement(UnsafeFileRequestPlaceholder);

export const FileRequestMessage = ({
  fileRequests,
  className,
}: {
  fileRequests: FileRequestWithRelations[];
  className?: string;
}) => {
  const seenTags = new Map<string, boolean>();

  return (
    <FileListPlaceholder.Container className={className}>
      {fileRequests.flatMap((fileRequest) =>
        fileRequest.placeholders
          // Filter out placeholders with tags we've already seen
          .filter((placeholder) => {
            if (seenTags.has(placeholder.tag_id)) {
              return false;
            }
            seenTags.set(placeholder.tag_id, true);
            return true;
          })
          .map((placeholder, index) => {
            if (placeholder.dirty) {
              return (
                <FileListPlaceholder
                  key={`${fileRequest.id}-${placeholder.id}`}
                  placeholder={placeholder}
                />
              );
            }

            return (
              <FileRequestPlaceholder
                key={`${fileRequest.id}-${placeholder.id}`}
                placeholder={placeholder}
              />
            );
          }),
      )}
    </FileListPlaceholder.Container>
  );
};
