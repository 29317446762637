'use client';

import { IconCaretDownFilled, IconChevronLeft } from '@tabler/icons-react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  cn,
} from 'ui';
import { Icon } from '@tabler/icons-react';
import { PathSlugs, useEvaluatePathSlug } from '../hooks/useEvaluatePathSlug';

export interface SettingsNavbarItemLink {
  nestedRouting?: boolean;
  title: string;
  href: string;
  matchSegment: string;
}

export interface SettingsNavbarItem
  extends Omit<SettingsNavbarItemLink, 'href' | 'matchSegment'> {
  icon: Icon;
  items: SettingsNavbarItemLink[];
}

export const SettingsLayout = ({
  children,
  items,
  nextSlugs = [],
  backPath,
}: {
  children: React.ReactNode;
  items: SettingsNavbarItem[];
  nextSlugs?: PathSlugs;
  backPath?: string;
}) => {
  const currentPath = usePathname();
  const isNextSlug = useEvaluatePathSlug(nextSlugs);

  return (
    <div className='w-full h-full min-h-screen py-2 flex bg-stone-100'>
      <div className='flex flex-row px-6 space-x-6 flex-grow'>
        <div className='flex-start items-start space-y-5 mt-[0.938rem] bg-stone-100'>
          <Link className='h-full pt-1' href={backPath || '/'}>
            <button className='flex flex-row items-center'>
              <IconChevronLeft
                style={{
                  marginRight: '0.375rem',
                }}
                className='size-4'
              />
              <h2 className='text-[0.938rem] font-medium text-stone-700'>
                Configuración
              </h2>
            </button>
          </Link>

          <div className='w-[12.56rem]'>
            <Accordion
              defaultValue={items.map((item) => item.title)}
              type='multiple'
            >
              {items.map((item) => {
                const [currentLink] = item.items
                  .filter((item) => currentPath.includes(item.matchSegment))
                  .sort(
                    (a, b) =>
                      (b.nestedRouting ? 1 : 0) - (a.nestedRouting ? 1 : 0),
                  )!;

                return (
                  <AccordionItem key={item.title} value={item.title}>
                    <AccordionTrigger
                      className='justify-normal pb-0 hover:no-underline'
                      withChevron={false}
                    >
                      <div className='flex flex-row items-center'>
                        <item.icon className='mr-[0.4375rem] size-[0.938rem] text-stone-400' />
                        <p className='text-xs font-medium text-stone-500'>
                          {item.title}
                        </p>
                      </div>

                      <IconCaretDownFilled
                        className={cn(
                          'text-stone-500 ml-[0.75rem] h-[0.625rem] w-[0.625rem] shrink-0 text-muted-foreground transition-transform duration-200',
                        )}
                      />
                    </AccordionTrigger>
                    <AccordionContent className='pt-[0.5rem]'>
                      <ul className='space-y-[0.5rem] ml-5'>
                        {item.items.map((item, index) => {
                          const path = `/settings/${item.href}`;

                          return (
                            <li className='w-full h-[1.75rem]' key={index}>
                              <Link
                                className={cn(
                                  'items-center px-[0.375rem] py-1 h-full transition-all flex rounded-[0.375rem] w-full hover:bg-stone-200 text-xs font-medium text-stone-700',
                                  {
                                    'bg-stone-300':
                                      currentLink?.title === item.title,
                                  },
                                )}
                                href={`${path}?q=${Math.floor(
                                  Math.random() * 100000,
                                )}`}
                              >
                                {item.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </div>
        </div>

        <div className='w-full'>
          <div className='bg-stone-50 border-stone-200 border rounded-[0.75rem] flex items-start justify-center py-11 h-full w-full'>
            <div
              className={cn('w-full', {
                'w-[40rem]': !isNextSlug,
              })}
            >
              {isNextSlug ? (
                <div className='px-[5rem] lg:px-[14rem] flex flex-row items-start justify-center'>
                  <div className='w-[40rem]'>{children}</div>
                </div>
              ) : (
                children
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
