import { Button, Spinner, toast } from 'ui';
import { Dialog, DialogContent, DialogTitle, DialogTrigger, cn } from 'ui';
import { Combobox } from 'ui';
import { IconX, IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { FileCardPreview } from 'shared-components';
import { useState, useCallback } from 'react';
import { ComboboxOption } from 'ui';
import { FileTypes, Tag } from 'database';
import { DialogClose } from '@radix-ui/react-dialog';

type File = {
  id: string;
  type: string;
  previewURL?: string;
  url?: string;
  name?: string;
};

interface ManualTaggingDialogProps {
  files: File[];
  searchTags: (input: { search: string }) => Promise<{ tags: Tag[] }>;
  createTag: (tag: string) => Promise<{ tag: Tag } | undefined>;
  updateFilesTagsBatch: (
    files: { id: string; tag_id: string }[],
  ) => Promise<any>;
}

type FileId = string;
type TagId = string;

export const ManualTaggingDialog = ({
  files,
  searchTags,
  createTag,
  updateFilesTagsBatch,
}: ManualTaggingDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filesTagsAdded, setFilesTagsAdded] = useState<Record<FileId, TagId>>(
    {},
  );

  const currentFileId = files[currentFileIndex]?.id || '';
  const currentFile = files[currentFileIndex];
  const filePreviewUrl = currentFile?.previewURL || currentFile?.url;

  const handlePreviousFile = useCallback(() => {
    setCurrentFileIndex((prevIndex) => {
      if (prevIndex <= 0) return 0;
      return prevIndex - 1;
    });
  }, []);

  const handleNextFile = useCallback(() => {
    setCurrentFileIndex((prevIndex) => {
      if (prevIndex >= files.length - 1) return files.length - 1;
      return prevIndex + 1;
    });
  }, [files.length]);

  const handleTagFile = useCallback(() => {
    setIsLoading(true);
    updateFilesTagsBatch(
      Object.entries(filesTagsAdded).map(([fileId, tagId]) => ({
        id: fileId,
        tag_id: tagId,
      })),
    )
      .then(() => {
        setCurrentFileIndex(0);
        setFilesTagsAdded({});
        setIsOpen(false);
      })
      .catch((error) => {
        toast({
          title: 'Error al etiquetar archivo',
          description: 'No se pudo etiquetar el archivo',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filesTagsAdded, updateFilesTagsBatch, setIsOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          variant='outline'
          className='h-8 truncate px-[.625rem] py-2 !text-base_brand border border-primary rounded-sm'
        >
          Etiquetar manualmente
        </Button>
      </DialogTrigger>
      <DialogContent className='rounded-sm p-4 max-w-[55.18rem]' hideClose>
        <div className='flex items-start justify-between mb-4'>
          <DialogTitle className='text-base font-semibold text-stone-700'>
            Etiquetar documentación no solicitada
          </DialogTitle>
          <DialogClose asChild>
            <Button variant='ghost'>
              <IconX className='w-6 h-6 text-stone-400' />
            </Button>
          </DialogClose>
        </div>

        {files.length > 0 && (
          <div className='flex flex-col space-y-6 w-full'>
            <div className='flex flex-col items-center justify-center h-[20rem]'>
              <FileCardPreview
                type={currentFile?.type as FileTypes}
                previewURL={filePreviewUrl}
                className='h-[20rem] max-w-[80%]'
                imageClassName='object-contain'
              />
              <p className='text-base_brand text-stone-700'>
                {currentFile?.name}
              </p>
              <div className='flex flex-row items-center space-x-2'>
                <Button
                  variant='ghost'
                  className='p-0'
                  onClick={handlePreviousFile}
                  disabled={currentFileIndex === 0}
                >
                  <IconChevronLeft className='w-3 h-3' />
                </Button>
                <span className='text-xs font-medium'>
                  {currentFileIndex + 1} / {files.length}
                </span>
                <Button
                  variant='ghost'
                  className='p-0'
                  onClick={handleNextFile}
                  disabled={currentFileIndex === files.length - 1}
                >
                  <IconChevronRight className='w-3 h-3' />
                </Button>
              </div>
            </div>

            <div className='flex items-center flex-col space-y-4 w-full'>
              <div className='flex flex-col space-y-2 w-full'>
                <span className='text-base_brand font-medium text-stone-700'>
                  Etiqueta
                </span>
                <Combobox
                  placeholder='Buscar y seleccionar etiqueta'
                  onSelect={({ value, label }) => {
                    setFilesTagsAdded((prev) => ({
                      ...prev,
                      [currentFileId]: value.toString(),
                    }));
                  }}
                  loadOptions={async (search) =>
                    searchTags({ search }).then((res) =>
                      res.tags.map((t) => ({
                        label: t.name,
                        value: t.id.toString(),
                      })),
                    )
                  }
                  defaultOptions={[]}
                  value={filesTagsAdded[currentFileId]?.toString() || ''}
                  emptyStateMessage='No se encontraron etiquetas'
                  className={cn('max-w-full text-base_brand')}
                  render={({ label }) => <>{label}</>}
                  onCreate={async (newTag): Promise<Tag> => {
                    const response = await createTag(newTag);
                    if (response?.tag) {
                      return response.tag;
                    }
                    throw new Error('Failed to create tag');
                  }}
                />
              </div>
              <div className='flex flex-row items-center justify-end w-full space-x-2'>
                <Button
                  onClick={handleTagFile}
                  disabled={
                    Object.keys(filesTagsAdded).length === 0 || isLoading
                  }
                >
                  Confirmar etiqueta
                  {isLoading && <Spinner className='w-4 h-4' />}
                </Button>
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
